<template>
  <section id="sponsors" class="ma-10">
    <div class="d-flex justify-space-between align-center">
      <div class="display-1 font-weight-bold">Manage Sponsors</div>
      <v-btn color="primary" @click="dialog = true">New Sponsor</v-btn>
    </div>
    <Table />
    <v-dialog persistent max-width="350" v-model="dialog">
      <v-card>
        <v-card-title
          class="d-flex justify-space-between align-center success white--text"
        >
          <div class="">New Sponsor</div>
          <v-btn icon small @click="dialog = false">
            <v-icon color="white" size="18">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form class="mt-5">
            <label class="caption">TITLE</label>
            <v-text-field v-model="title" />
            <label class="caption">SUBTITLE</label>
            <v-text-field v-model="subtitle" />
            <label class="caption">IMAGE</label>
            <v-file-input v-model="image" />
          </v-form>
          <div class="d-flex align-center justify-end">
            <v-btn v-if="!saving" color="success" @click="save">Save</v-btn>
            <v-btn v-else color="success">Saving...</v-btn>
            <v-btn text @click="dialog = false">Cancel</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <snackbar
      :snack="snack"
      text="Sponsor Save"
      timeout="3000"
      @close="snack = false"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Table from "../components/sponsor/Table.vue";

export default {
  components: {
    Table,
  },
  data: () => ({
    tab: null,
    dialog: false,
    title: "",
    subtitle: "",
    image: "",
    saving: false,
    snack: false,
  }),
  computed: {
    ...mapState("admin", {
      sponsors: (state) => state.sponsors,
      errors: (state) => state.errors,
    }),
  },
  created() {
    this.getSponsors();
  },
  methods: {
    ...mapActions("admin", ["addSponsor", "getSponsors"]),

    save() {
      let form = new FormData();
      form.append("title", this.title);
      form.append("subtitle", this.subtitle);
      form.append("image", this.image);
      this.saving = true;
      this.addSponsor(form).then(() => {
        if (this.$errors("admin")) {
          console.log(this.errors);
          return;
        }
        this.saving = false;
        this.dialog = false;
        this.snack = true;
        this.title = "";
        this.subtitle = "";
        this.image = "";
      });
    },
  },
};
</script>
<template>
  <v-simple-table fixed-header loading="true" class="simple-table pt-1">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left caption">Image</th>
          <th class="text-left caption">Title</th>
          <th class="text-left caption">Subtitle</th>
          <th class="text-left caption">Added</th>
        </tr>
      </thead>
      <tbody class="table-body">
        <loading-table col="6" v-if="loading" />

        <tr v-for="item in data" :key="item.title">
          <td>
            <v-avatar color="success" size="36"></v-avatar>
          </td>
          <td class="">{{ item.title }}</td>
          <td class="">{{ item.subtitle }}</td>
          <td class="">{{ $helpers.dateDiff(item.created_at) }}</td>
          <td>
            <v-btn icon class="mx-2" @click="$emit('delete', item.id)">
              <v-icon small>mdi-delete-outline</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style>
</style>